
import { defineComponent, computed, watch, onMounted } from "vue";
import ListMember from "@/components/shared/ListMember.vue";
import Tasks from "@/views/main/platform/_children/overview/parts/Tasks.vue";
import Relates from "@/views/main/platform/_children/overview/parts/Relates.vue";
import Files from "@/components/widgets/tables/Files.vue";
import { useStore } from "vuex";
import { setPlatformOverview } from "@/core/helpers/platform";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/config";
export default defineComponent({
  name: "account-overview",
  components: {
    Tasks,
    Relates,
    ListMember,
    Files,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const platformOverviewData = computed(() => {
      return store.getters.platformOverviewData;
    });

    const id = Number(route.params.id);
    setPlatformOverview(id);
    onMounted(() => {
      setCurrentPageBreadcrumbs("Platforms", ["Pages", "Profile", "Overview"]);
    });
    watch(
      () => route.params.id,
      async (newId) => {
        await setPlatformOverview(Number(newId));
      }
    );

    return { platformOverviewData };
  },
});
