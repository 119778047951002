
import { defineComponent } from "vue";
export default defineComponent({
  name: "title_subtitle",
  props: {
    title: String,
    subtitle: String,
    lead: String,
  },
});
