import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center gap-4" }
const _hoisted_2 = { class: "ms-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleAndSubtitle = _resolveComponent("TitleAndSubtitle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "left"),
    _createVNode(_component_TitleAndSubtitle, {
      title: _ctx.title,
      subtitle: _ctx.subtitle,
      lead: _ctx.lead
    }, null, 8, ["title", "subtitle", "lead"]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "right")
    ])
  ]))
}