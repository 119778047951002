
import { computed, defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { setModal } from "@/core/helpers/config";
import DetailItem from "@/components/shared/DetailItem.vue";
import HeaderTitle from "@/components/shared/HeaderTitle.vue";
import useTranslate from "@/core/composable/useTranslate";

export default defineComponent({
  name: "tasks",
  components: {
    DetailItem,
    HeaderTitle,
  },
  props: {
    widgetClasses: String,
    tasks: Array,
  },
  setup(props) {
    const { translate } = useTranslate();
    const handleChangeStateModal = () => {
      setModal("KTCreateNewTicket");
    };
    const subtitle = computed(() => {
      return `${translate("platformsInfo.from")}  ${translate(
        "platformsInfo.total"
      )} ${props.tasks?.length} 
       ${translate("platformsInfo.ticket")}`;
    });
    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      handleChangeStateModal,
      subtitle,
    };
  },
});
