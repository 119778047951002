
import { defineComponent, onMounted, computed } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import HeaderTitle from "@/components/shared/HeaderTitle.vue";
import DetailItem from "@/components/shared/DetailItem.vue";
export default defineComponent({
  name: "Relates",
  components: { HeaderTitle, DetailItem },
  props: {
    widgetClasses: String,
    platform: Object,
    projects: Array,
  },
  setup(props) {
    onMounted(() => {
      MenuComponent.reinitialization();
    });
    const adminUser = computed(() => {
      return (
        props.platform?.adminUser?.firstName +
        " " +
        props.platform?.adminUser?.lastName
      );
    });
    return {
      adminUser,
    };
  },
});
