import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-013c21a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "overview-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Relates = _resolveComponent("Relates")!
  const _component_Tasks = _resolveComponent("Tasks")!
  const _component_ListMember = _resolveComponent("ListMember")!
  const _component_Files = _resolveComponent("Files")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_Relates, {
        platform: _ctx.platformOverviewData.platform,
        projects: _ctx.platformOverviewData.projects
      }, null, 8, ["platform", "projects"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_Tasks, {
        tasks: _ctx.platformOverviewData.tasks
      }, null, 8, ["tasks"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_ListMember, {
        listMembers: _ctx.platformOverviewData.listMembers
      }, null, 8, ["listMembers"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_Files, {
        files: _ctx.platformOverviewData.latestFiles
      }, null, 8, ["files"])
    ])
  ]))
}