
import { defineComponent } from "vue";
import TitleAndSubtitle from "@/components/shared/TitleAndSubtitle.vue";
export default defineComponent({
  name: "detail_item",
  components: {
    TitleAndSubtitle,
  },
  props: {
    title: String,
    subtitle: String,
    lead: String,
  },
});
