
import { computed, defineComponent } from "vue";
import DetailItem from "@/components/shared/DetailItem.vue";
import HeaderTitle from "@/components/shared/HeaderTitle.vue";
import useTranslate from "@/core/composable/useTranslate";
export default defineComponent({
  name: "files",
  components: { DetailItem, HeaderTitle },
  props: {
    widgetClasses: String,
    files: Array,
  },
  setup(props) {
    const { translate } = useTranslate();
    const items = [
      {
        icon: "svg/files/pdf.svg",
        title: "Platform tech requirements",
        size: "5.4MB",
        uploadedby: "Karina Clark",
        created: "2 days ago",
      },
      {
        icon: "svg/files/doc.svg",
        title: "Create FureStibe branding logo",
        size: "2.8MB",
        uploadedby: "Karina Clark",
        created: "Due in 1 day",
      },
      {
        icon: "svg/files/pdf.svg",
        title: "Create FureStibe branding logo",
        users: "890 Users",
        size: "1.5MB",
        uploadedby: "Karina Clark",
        created: "Due in 1 day",
      },
      {
        icon: "svg/files/pdf.svg",
        title: "Create FureStibe branding logo",
        size: "5.4MB",
        uploadedby: "Karina Clark",
        created: "Due in 1 day",
      },
      {
        icon: "svg/files/doc.svg",
        title: "Create FureStibe branding logo",
        size: "5.4MB",
        uploadedby: "Karina Clark",
        created: "Due in 1 day",
      },
    ];
    const subtitle = computed(() => {
      return `${translate("platformsInfo.total")} ${props.files?.length} 
       ${translate("files")}`;
    });
    return {
      items,
      subtitle,
    };
  },
});
