import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-block mb-0 fs-7" }
const _hoisted_2 = { class: "text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_router_link, {
      to: "#",
      class: "text-gray-800 text-hover-primary fw-bold fs-5"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }),
    _createElementVNode("p", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.subtitle), 1),
      (_ctx.lead)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: "#",
            class: "text-primary ms-1"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.lead), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}