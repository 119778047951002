import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "card px-6" }
const _hoisted_2 = { class: "card-body pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_HeaderTitle = _resolveComponent("HeaderTitle")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_DetailItem = _resolveComponent("DetailItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderTitle, {
      title: _ctx.$t('latestFiles'),
      subtitle: _ctx.subtitle
    }, {
      default: _withCtx(() => [
        _createVNode(_component_router_link, {
          to: { name: 'files' },
          class: "btn btn-sm btn-light"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("platformsInfo.ViewAll")), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["title", "subtitle"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "mb-4"
        }, [
          _createVNode(_component_DetailItem, {
            title: item.title,
            subtitle: item.created,
            lead: item.uploadedby
          }, {
            left: _withCtx(() => [
              _createVNode(_component_inline_svg, {
                src: item.icon,
                width: "40",
                height: "40"
              }, null, 8, ["src"])
            ]),
            _: 2
          }, 1032, ["title", "subtitle", "lead"])
        ]))
      }), 128))
    ])
  ]))
}